import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import stampcheckLogo from "../images/stampcheckLogo.svg"
import stampcheckverifyLogo from "../images/stampcheckverifyLogo.svg"
import awesome from "../images/awesome.svg"
import stampcheckConfirmationLogo from "../images/stampcheckConfirmationLogo.svg"
const Stampcheck = () => {
    return (
        <Layout currentPage={'Stampcheck'}>
            <Seo title="Stampcheck" description="Check if a digital asset was EviStamped" />
            <div>
            <section className="stampcheck-heading">
                    <div className="stampcheck-heading-main">
                        <article>
                            <h6>Check if a</h6>
                            <h3>Digital Asset Was EviStamped</h3>
                        </article>
                        <article>
                            <img src={stampcheckLogo}/>
                        </article>
                    </div>
                </section>

            <section className="stampcheck-verify">
                    <div className="stampcheck-verify-main">
                        <article>
                            <img src={stampcheckverifyLogo}/>
                        </article>
                        <article>
                            <h6>Step 1 Chose</h6>
                            <h3>The file you want to verify</h3>
                            <div className="main-orginal-file">
                            <div className="orginalfile">
                                <div className="words">
                                    <p className="firstparagraf">Original file</p>
                                    <p className="secondparagraf">Ex.Document.pdf</p>
                                </div>
                                <div className="orginalfile-box">
                                        <a href="#"><img src={awesome}/></a>
                                        <p>Drag and drop your file or Upload it</p>
                                    </div>
                            </div>
                            <div className="orginalfile">
                                <div className="words">
                                    <p className="firstparagraf">Timestamp file</p>
                                    <p className="secondparagraf">Ex.Document.pdf evistmp</p>
                                </div>
                                <div className="orginalfile-box">
                                        <a href="#"><img src={awesome}/></a>
                                        <p>Drag and drop your file or Upload it</p>
                                    </div>
                            </div>
                            </div>
                            <div className="verify-btn">
                                <button>Submit</button>
                            </div>
                        </article>
                    </div>
                </section>

            <section className="stampcheck-confirmation">
                    <div className="stampcheck-confirmation-main">
                        <article>
                            <h6>Step 2 Get</h6>
                            <h3>The confirmation</h3>
                            <p>Success Document was EviStamped.Log In to see details.</p>
                        </article>
                        <article>
                            <img src={stampcheckConfirmationLogo}/>
                        </article>
                    </div>
                </section>
            </div>
        </Layout>
    )
}
export default Stampcheck